define("discourse/plugins/discourse-unhandled-tagger/connectors/topic-footer-main-buttons-before-create/handled-button", ["exports", "@ember/object", "discourse/models/topic"], function (_exports, _object, _topic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = dt7948.p({
    shouldRender(args, component) {
      return component.siteSettings.tagging_enabled && component.siteSettings.unhandled_tag;
    },
    setupComponent(_, component) {
      (0, _object.defineProperty)(component, "showHandled", (0, _object.computed)("currentUser.staff", "args.topic.isPrivateMessage", () => this.currentUser && this.currentUser.staff && !this.args.topic.isPrivateMessage));
      (0, _object.defineProperty)(component, "handled", (0, _object.computed)("args.topic.tags.[]", "siteSettings.unhandled_tag", () => !this.args.topic.tags || !this.args.topic.tags.includes(this.siteSettings.unhandled_tag)));
    },
    setUnhandled(value) {
      const {
        topic
      } = this.args;
      const tags = topic.tags;
      tags.removeObject(this.siteSettings.unhandled_tag);
      if (value) {
        tags.addObject(this.siteSettings.unhandled_tag);
      }
      return _topic.default.update(topic, {
        tags
      }).then(() => {
        this.appEvents.trigger("header:show-topic", topic);
      });
    }
  }, [["method", "setUnhandled", [_object.action]]]);
});